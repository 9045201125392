<template>
  <div class="mainCtn">
    <UserSettingsSideBar @onSave="onSave" @onEditParent="onEditParent" :open="true" />
    <AccountSettingsSideBar @onSave="onSaveSettings" :open="true" />
    <div class="block1">
      <b-form-file
        ref="avatarInput"
        class="inputAvatar"
        :state="Boolean(true)"
        accept="image/*"
        @change="onUploadFile($event, 'avatar')"
      />
      <vs-avatar circle size="100" badge badge-color="success" badge-position="top-left">
        <img :src="user ? user.profileImg : portadaImg" alt="" />
        <template #icons>
          <i v-if="!isExternal" @click="onChangeImg($event, 'avatar')" class="fa fa-pen"></i>
          <i v-else class="fas fa-comment-dots"></i>
          <!-- <i class="fa fa-search"></i>
          <i class="fa fa-bell"></i> -->
        </template>
      </vs-avatar>
      <h1 class="name">{{ user.nombre || "" }} {{ user.apellidos || "" }}</h1>
      <h2 class="subtitle">@{{ user.username || "" }}</h2>
      <div class="countInfo">
        <div @click="(tab = 0), (subtab = 0), scrollTo()">
          <h3>{{ user.followers || "" }}</h3>
          followers
        </div>
        <div @click="(tab = 1), (subtab = 2), scrollTo()">
          <h3>{{ createdEvents.length + participatedEvents.length }}</h3>
          eventos
        </div>
        <div @click="(tab = 2), (subtab = 4), scrollTo()">
          <h3>{{ stories.length + media.length }}</h3>
          stories
        </div>
      </div>
    </div>
    <div ref="block2" class="block2">
      <div class="tabs">
        <vs-button v-if="tab === 0" transparent dark @click="subtab = 0">
          <b :class="subtab === 0 ? 'tab selected' : 'tab'">Followers</b>
        </vs-button>
        <vs-button v-if="tab === 0" transparent dark @click="subtab = 1">
          <b :class="subtab === 1 ? 'tab selected' : 'tab'">Siguiendo</b>
        </vs-button>
        <vs-button v-if="tab === 1" transparent dark @click="subtab = 2">
          <b :class="subtab === 2 ? 'tab selected' : 'tab'">Creados</b>
        </vs-button>
        <vs-button v-if="tab === 1" transparent dark @click="subtab = 3">
          <b :class="subtab === 3 ? 'tab selected' : 'tab'">Participados</b>
        </vs-button>
        <vs-button v-if="tab === 2" transparent dark @click="subtab = 4">
          <b :class="subtab === 4 ? 'tab selected' : 'tab'">Stories</b>
        </vs-button>
        <vs-button v-if="tab === 2" transparent dark @click="subtab = 5">
          <b :class="subtab === 5 ? 'tab selected' : 'tab'">Fotos y videos</b>
        </vs-button>
      </div>
      <br />
      <div class="profilesCard" v-if="subtab === 0">
        <vs-input style="width:calc(100vw - 20vw)" placeholder="Buscar perfil">
          <template #icon>
            <i class="fas fa-search"></i>
          </template>
        </vs-input>
        <div class="profileCard" v-for="(a, ind) in followedUsers" :key="'card_' + ind">
          <div>
            <img
              class="profileImg"
              :src="a.authorImg || portadaImg"
              width="50px"
              height="50px"
              alt=""
            />
          </div>
          <div class="profileData">
            <div>
              <b>@{{ a.username }}</b>
            </div>
            <div>
              <em style="font-size:14px; color:gray;">
                10 contactos en común
              </em>
            </div>
          </div>
          <div style="width:10%; color: green; font-size:30px">
            +
          </div>
        </div>
      </div>
      <div class="profilesCard" v-if="subtab === 1">
        <vs-input style="width:calc(100vw - 20vw)" placeholder="Buscar perfil">
          <template #icon>
            <i class="fas fa-search"></i>
          </template>
        </vs-input>
        <div class="profileCard" v-for="(a, ind) in followingUsers" :key="'card_' + ind">
          <div>
            <img
              class="profileImg"
              :src="a.authorImg || portadaImg"
              width="50px"
              height="50px"
              alt=""
            />
          </div>
          <div class="profileData">
            <div>
              <b>@{{ a.username }}</b>
            </div>
            <div>
              <em style="font-size:14px; color:gray;">
                10 contactos en común
              </em>
            </div>
          </div>
          <div style="width:10%; color: green; font-size:30px">
            +
          </div>
        </div>
      </div>
      <div class="profilesCard" v-if="subtab === 2">
        <vs-input style="width:calc(100vw - 20vw)" placeholder="Buscar perfil">
          <template #icon>
            <i class="fas fa-search"></i>
          </template>
        </vs-input>
        <br />
        <CardGrid v-bind:cards="createdEvents" />
      </div>
      <div class="profilesCard" v-if="subtab === 3">
        <vs-input style="width:calc(100vw - 20vw)" placeholder="Buscar perfil">
          <template #icon>
            <i class="fas fa-search"></i>
          </template>
        </vs-input>
        <CardGrid v-bind:cards="participatedEvents" />
      </div>
      <div class="profilesCard" v-if="subtab === 4">
        <vs-input style="width:calc(100vw - 20vw)" placeholder="Buscar perfil">
          <template #icon>
            <i class="fas fa-search"></i>
          </template>
        </vs-input>
        <div class="profileCard" v-for="(a, ind) in followingUsers" :key="'card_' + ind">
          <div>
            <img
              class="profileImg"
              :src="a.authorImg || portadaImg"
              width="50px"
              height="50px"
              alt=""
            />
          </div>
          <div class="profileData">
            <div>
              <b>@{{ a.username }}</b>
            </div>
            <div>
              <em style="font-size:14px; color:gray;">
                10 contactos en común
              </em>
            </div>
          </div>
          <div style="width:10%; color: green; font-size:30px">
            +
          </div>
        </div>
      </div>
      <div class="profilesCard" v-if="subtab === 5">
        <vs-input style="width:calc(100vw - 20vw)" placeholder="Buscar perfil">
          <template #icon>
            <i class="fas fa-search"></i>
          </template>
        </vs-input>
        <div class="profileCard" v-for="(a, ind) in followingUsers" :key="'card_' + ind">
          <div>
            <img
              class="profileImg"
              :src="a.authorImg || portadaImg"
              width="50px"
              height="50px"
              alt=""
            />
          </div>
          <div class="profileData">
            <div>
              <b>@{{ a.username }}</b>
            </div>
            <div>
              <em style="font-size:14px; color:gray;">
                10 contactos en común
              </em>
            </div>
          </div>
          <div style="width:10%; color: green; font-size:30px">
            +
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  loadingMix,
  noficationsDialog,
  authMix,
  eventMix,
  followMix,
  chatMix
} from "@/common/mixin.js";
import AccountSettingsSideBar from "@/components/SIDEBARS/AccountSettingsSB.vue";
import UserSettingsSideBar from "@/components/SIDEBARS/UserSettingsSB.vue";
import CardGrid from "@/components/CardGrid.vue";
import { mapState } from "vuex";

export default {
  name: "Profile2",
  computed: mapState(["currentUser", "showUserSettingsSB"]),
  components: { CardGrid, UserSettingsSideBar, AccountSettingsSideBar },
  mixins: [loadingMix, noficationsDialog, authMix, eventMix, followMix, chatMix],
  methods: {
    onInit(params = null) {
      const { id } = this.$store.state.currentUser;
      this.$route.params.creator = params
        ? params.creator || this.$route.params.creator
        : this.$route.params.creator;
      const { creator } = this.$route.params;
      if (creator && creator.id && id) {
        this.isExternal = creator.id !== id;
        const profileId = this.isExternal ? creator.id : id;
        this.getProfileData(profileId);
      } else {
        this.isExternal = false;
        this.getProfileData(id);
      }
    },
    async getProfileData(externalUser = null) {
      const currentUser = (await this.firebaseCurrentUser()) || null;
      const profileId = externalUser ? externalUser : currentUser ? currentUser.uid : null;
      if (profileId) {
        this.user = await this.firebaseGetUser(profileId);
        console.log(this.user);
        this.profileType = this.user.type;
        this.getEvents(profileId);
        this.getFollowing(this.user, profileId);
        if (externalUser) {
          this.checkFollowed();
        }
        try {
          this.avatarImg = await this.firebaseGetProfileImg(profileId);
        } catch (error) {
          console.log(error);
        }
      }
    },
    async getFollowing(user, id) {
      this.followingUsers = user.followDetail.filter(a => a.created_by === id);
      this.followedUsers = user.followDetail.filter(a => a.created_by !== id);
    },

    async getEvents(user_uid) {
      this.createdEvents = await this.getFilteredEvents("created_by", user_uid);
      this.participatedEvents = await this.getFilteredEvents(
        "participants",
        user_uid,
        "array-contains"
      );
    },

    scrollTo() {
      const el = this.$refs.block2;
      if (el) {
        window.scroll({
          top: el.scrollHeight,
          left: 0,
          behavior: "smooth"
        });
        el.scrollIntoView({ behavior: "smooth" });
      }
    },

    checkFollowed() {
      const { currentUser } = this.$store.state;
      const { id } = currentUser;
      const ind = this.user.followDetail
        ? this.user.followDetail.findIndex(detail => detail.created_by === id)
        : -1;
      this.isFollowed = ind > -1;
    },

    async onSave(data) {
      if (this.isSettingsMode) {
        this.onSaveSettings();
      }
      const currentUser = await this.firebaseCurrentUser();
      if (this.previewImgFile && currentUser) {
        await this.firebaseSetProfileImg(currentUser.uid, this.previewImgFile);
      } else if (data) {
        const { password } = data;
        if (password && password !== "") {
          currentUser.updatePassword(password);
        } else {
          delete data.password;
        }
        await this.firebaseUpdateUser(currentUser.uid, data);
      }
      const user = await this.firebaseGetUser(currentUser.uid);
      this.$store.commit("setUser", user);
      this.previewImgFile = null;
      this.isEditMode = false;
    },

    onSaveSettings() {
      this.isEditMode = false;
      this.isSettingsMode = false;
    },

    onEditParent() {
      this.isEditMode = !this.isEditMode;
    },

    onChangeImg(event) {
      event.stopPropagation();
      event.cancelBubble = true;
      this.$refs.avatarInput.$el.children[0].click();
    },

    onUploadFile(oEvent) {
      const image = oEvent.target.files[0];
      this.previewImgFile = image;
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = oEvent2 => {
        const { result } = oEvent2.target;
        this.previewImage = result;
        this.user.profileImg = result;
        this.onSave();
      };
      // oEvent.stopPropagation();
      // oEvent.cancelBubble = true;
    }
  },
  watch: {
    currentUser(newValue, oldValue) {
      if (newValue) {
        this.user = newValue;
        this.avatarImg = newValue.profileImg;
        this.getProfileData(newValue.id);
      }
    },
    showUserSettingsSB(newValue, oldValue) {
      this.isEditMode = newValue;
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      this.isMobile = isMobile;
    }
    this.onInit();
  },
  beforeRouteUpdate(to, from, next) {
    this.onInit(to.params);
    next();
  },
  data() {
    return {
      user: {},
      tab: 0,
      subtab: 0,
      isMobile: false,
      isEditMode: false,
      isSettingsMode: false,
      createdEvents: [],
      participatedEvents: [],
      followingUsers: [],
      followedUsers: [],
      stories: [],
      media: [],
      previewImgFile: null,
      privateImg:
        "https://p1.pxfuel.com/preview/272/573/172/iphone-taking-photo-phone-anonymous-royalty-free-thumbnail.jpg",
      portadaImg:
        "https://c0.wallpaperflare.com/preview/217/9/286/fun-event-crowd-entertainment.jpg"
    };
  }
};
</script>

<style scoped>
@media (max-width: 575px) {
  .mainCtn {
    background: #fff;
  }

  .block1 {
    background: #ffffff;
    height: 40vh;
    border-bottom-left-radius: 3rem;
    border-bottom-right-radius: 3rem;
  }

  .block2 {
    padding: 0.5rem;
    height: calc(100vh - 45vh);
  }

  .avatarImg {
    border-radius: 50%;
    margin-bottom: 0.5rem;
  }

  .profileImg {
    border-radius: 50%;
  }

  .profilesCard {
    padding: 0.5rem;
  }

  .profileCard {
    display: flex;
    justify-content: flex-start;
    padding: 0.5rem;
  }

  .profileData {
    text-align: start;
    padding-left: 1rem;
    width: 80%;
  }

  .countInfo {
    padding: 1rem;
    display: flex;
    justify-content: space-evenly;
    color: #000;
  }

  .tabs {
    display: flex;
    justify-content: space-evenly;
  }

  .tab.selected {
    text-decoration: 3px solid #ee3859 underline;
  }

  h1.name {
    font-size: 20px;
    color: #000;
  }

  .subtitle {
    font-size: 15px;
    color: #700827;
  }
}

@media (min-width: 576px) {
}

.inputAvatar {
  display: none;
}
</style>

<style>
.block1 > .vs-avatar-content {
  margin: 1rem auto;
}

.block1 > .vs-avatar-content > .vs-avatar > img {
  height: 100%;
}

.block1 > .vs-avatar-content > .vs-avatar__icons {
  display: flex;
  justify-content: flex-end;
  /* justify-content: space-around; */
  color: #e91e63;
  background: transparent;
}

.block1 > .vs-avatar-content > .vs-avatar__badge {
  width: 20px;
  height: 20px;
}
</style>
